import RestService from '@/services/RestService'
import axios from 'axios'
import utility from '@/common/utility'
import auth from '@/common/auth'

let resourceName = 'g18'

let batchAddUrl = 'batchAdd'
let defaultDataUrl = 'defaultData'
// let tenderStatUrl = 'tenderStats'
let tenderStatUrl = 'tenderStatDev'
let dateTenderStatUrl = 'dateTenderStat'
let otherTenderStatUrl = 'otherTenderStats'
let g18OtherUrl = 'g18Other'

class G18Service extends RestService {
  batchAdd (g18Models) {
    g18Models.forEach(g18Model => {
      g18Model.generatePrimaryKey()
    })
    return axios.post(utility.getRestFullUrl(this.resourceName, batchAddUrl), g18Models, {
      headers: {
        'Authorization': utility.getAuthHeader(auth.getToken())
      }
    })
  }

  getDefaultData (tenderGuid, planStatExaminationGuid, year, month, date) {
    return axios.get(utility.getRestFullUrl(this.resourceName, defaultDataUrl), {
      params: {
        tenderGuid,
        planStatExaminationGuid,
        year,
        month,
        date
      },
      headers: {
        'Authorization': utility.getAuthHeader(auth.getToken())
      }
    })
  }

  getDateTenderStat (reportDate, projectGuid, engineeringType = '土建') {
    return axios.get(utility.getRestFullUrl(this.resourceName, dateTenderStatUrl), {
      params: {
        reportDate,
        projectGuid,
        engineeringType
      },
      headers: {
        'Authorization': utility.getAuthHeader(auth.getToken())
      }
    })
  }

  getTenderStat (reportDate, projectGuid) {
    return axios.get(utility.getRestFullUrl(this.resourceName, tenderStatUrl), {
      params: {
        reportDate,
        projectGuid
      },
      headers: {
        'Authorization': utility.getAuthHeader(auth.getToken())
      }
    })
  }

  getOtherTenderStat (projectGuid, reportDate, engineeringType = '土建') {
    return axios.get(utility.getRestFullUrl(this.resourceName, otherTenderStatUrl), {
      params: {
        projectGuid,
        reportDate,
        engineeringType
      },
      headers: {
        'Authorization': utility.getAuthHeader(auth.getToken())
      }
    })
  }

  getG18Other (tenderGuid, reportDate) {
    return axios.get(utility.getRestFullUrl(this.resourceName, g18OtherUrl), {
      params: {
        tenderGuid,
        reportDate
      },
      headers: {
        'Authorization': utility.getAuthHeader(auth.getToken())
      }
    })
  }
}

const g18Service = new G18Service(resourceName)

export default g18Service
