<template>
  <div class="g18">
    <my-search-bar>
      <el-form :inline="true">
        <el-form-item>
          <el-button type="primary" @click="handleSaveClick">保存</el-button>
        </el-form-item>
      </el-form>
    </my-search-bar>
    <div class="spread-wrapper">
      <my-spread
        v-if="defaultDataList.length"
        ref="mySpread"
        :spread-info="spreadInfo"
        :default-data-list="defaultDataList"
        :escapeRows="escapeRows"
        >
      </my-spread>
    </div>
  </div>
</template>

<script>
import utility from '@/common/utility'
import MySearchBar from '@/components/MySearchBar/MySearchBar'
import G18SpreadInfo from '@/views/PlanStatManage/HeaderColumns/G18SpreadInfo'
import g18Service from '@/services/g18Service'
import MySpread from '@/components/Spread/MySpreadOld'
import G18Model from '@/model/G18Model'
import { mapGetters } from 'vuex'

export default {
  name: 'g18',
  components: {
    MySpread,
    MySearchBar
  },
  data () {
    return {
      defaultDataList: [],
      spreadInfo: G18SpreadInfo,
      escapeRows: []
    }
  },
  computed: {
    ...mapGetters('planStatExamination', {
      planStatExaminationGuid: 'getPlanStatExaminationGuid',
      tenderGuid: 'getTenderGuid',
      tenderName: 'getTenderName',
      year: 'getYear',
      month: 'getMonth',
      date: 'getDate',
      approvalUserGuid: 'getApprovalUserGuid'
    })
  },
  methods: {
    handleSaveClick () {
      const num = this.$refs.mySpread.getRow(0)
      console.log(num.mainLineNum)
      if (isNaN(num.mainLineNum)) {
        this.$message({
          type: 'warning',
          message: '请检查当日工作量输入是否正确！'
        })
        return
      }
      // 检查是否有错误
      this.$refs.mySpread.validate()
      if (this.$refs.mySpread.errorKeys.length === 0) {
        this.$myLoading()
        let data = this.$refs.mySpread.getData().map((item, index) => {
          item.planStatExaminationGuid = this.planStatExaminationGuid
          item.engineeringListGuid = this.defaultDataList[index].engineeringListGuid
          item.tenderGuid = this.tenderGuid
          item.year = this.year
          item.month = this.month
          item.date = this.date
          item.mainLineNum = !item.mainLineNum ? '' : item.mainLineNum.toString()
          return item
        }).filter(item => (item.currentDateNum !== '' || item.engineeringListGuid.indexOf('0-') === 0)).map(item => {
          return new G18Model(item)
        })
        g18Service.batchAdd(data)
          .then(res => {
            this.$loading().close()
            this.$message({
              type: 'success',
              message: '保存成功！'
            })
            this.$router.push('/g18Index')
          })
          .catch(err => {
            console.log(err)
            this.$loading().close()
            this.$message({
              type: 'error',
              message: '保存失败，请重试！'
            })
          })
      } else {
        this.$message({
          type: 'error',
          message: '校验失败，请检查您的输入！'
        })
      }
    },
    getDefaultData () {
      g18Service.getDefaultData(this.tenderGuid, this.planStatExaminationGuid, this.year, this.month, this.date)
        .then(res => {
          this.resArray = []
          if (res.data.code === 1) {
            this.resArray = res.data.data.map((item, i) => {
              item.serialNo = i + 1
              return item
            })
            this.resArray.unshift({
              engineeringListCode: '0-1',
              engineeringListGuid: '0-1',
              engineeringListName: '当日完成工作量',
              num: '',
              currentMonthNum: '',
              parentEngineeringListCode: '',
              parentEngineeringListName: '当日完成工作量',
              sortId: 0,
              unit: '万元',
              tenderGuid: this.tenderGuid
            })
            this.resArray.push({
              engineeringListCode: '0-2',
              engineeringListGuid: '0-2',
              engineeringListName: '驻地、拌合站、梁场建设完成情况',
              num: '',
              currentMonthNum: '',
              parentEngineeringListCode: '',
              parentEngineeringListName: '驻地、拌合站、梁场建设完成情况',
              sortId: 0,
              unit: '',
              tenderGuid: this.tenderGuid
            })
            this.resArray.push({
              engineeringListCode: '0-3',
              engineeringListGuid: '0-3',
              engineeringListName: '主要材料进场情况',
              num: '',
              currentMonthNum: '',
              parentEngineeringListCode: '',
              parentEngineeringListName: '主要材料进场情况',
              sortId: 0,
              unit: '',
              tenderGuid: this.tenderGuid
            })
            this.resArray.push({
              engineeringListCode: '0-4',
              engineeringListGuid: '0-4',
              engineeringListName: '施工作业面开展情况',
              num: '',
              currentMonthNum: '',
              parentEngineeringListCode: '',
              parentEngineeringListName: '施工作业面开展情况',
              sortId: 0,
              unit: '',
              tenderGuid: this.tenderGuid
            })
            this.resArray.push({
              engineeringListCode: '0-5',
              engineeringListGuid: '0-5',
              engineeringListName: '项目完成情况说明',
              num: '',
              currentMonthNum: '',
              parentEngineeringListCode: '',
              parentEngineeringListName: '项目完成情况说明',
              sortId: 0,
              unit: '',
              tenderGuid: this.tenderGuid
            })
            this.escapeRows = [0]
            for (let i = 1; i <= 4; ++i) {
              this.escapeRows.push(this.resArray.length - i)
            }
            this.getG18Other()
          } else {
            this.$message({
              type: 'error',
              message: res.data.msg
            })
          }
        })
        .catch(err => {
          console.log(err)
          this.$message({
            type: 'error',
            message: '数据加载失败，请重试！'
          })
        })
    },
    mergeCells () {
      this.$refs.mySpread.worksheet.addSpan(0, 1, 1, 2)
      this.$refs.mySpread.worksheet.addSpan(0, 6, 1, 4)

      for (let i = 1; i <= 4; ++i) {
        this.$refs.mySpread.worksheet.addSpan(this.defaultDataList.length - i, 1, 1, 2)
        this.$refs.mySpread.worksheet.addSpan(this.defaultDataList.length - i, 6, 1, 4)
      }
    },
    getG18Other () {
      const reportDate = `${this.year}${utility.padStart(this.month, 2)}${utility.padStart(this.date, 2)}`
      g18Service.getG18Other(this.tenderGuid, reportDate)
        .then(res => {
          if (res.data.code === 1) {
            const otherTenderStats = res.data.data
            if (otherTenderStats.length === 5) {
              const lastRowIndex = this.resArray.length - 1
              this.resArray[0].mainLineNum = otherTenderStats.find(item => item.engineeringListGuid === '0-1').content
              this.resArray[lastRowIndex - 3].mainLineNum = otherTenderStats.find(item => item.engineeringListGuid === '0-2').content
              this.resArray[lastRowIndex - 2].mainLineNum = otherTenderStats.find(item => item.engineeringListGuid === '0-3').content
              this.resArray[lastRowIndex - 1].mainLineNum = otherTenderStats.find(item => item.engineeringListGuid === '0-4').content
              this.resArray[lastRowIndex].mainLineNum = otherTenderStats.find(item => item.engineeringListGuid === '0-5').content
            }

            this.defaultDataList = this.resArray
            this.$nextTick(() => {
              this.mergeCells()
            })
          }
        })
        .catch(err => {
          console.log(err)
          this.$message({
            type: 'error',
            message: '加载列表失败'
          })
        })
    }
  },
  created () {
    this.getDefaultData()
  }
}
</script>

<style scoped lang="scss">
.g18 {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  .spread-wrapper {
    flex: 1;
  }
}
</style>
