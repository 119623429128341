import utility from '@/common/utility'

const G18SpreadInfo = {
  sheetName: 'G18',
  columns: [
    {
      label: '序号',
      dataKey: 'serialNo',
      width: '50'
    },
    {
      label: '工程名称',
      dataKey: 'engineeringListName',
      width: '100',
      children: [
        {
          label: '工程类别',
          dataKey: 'parentEngineeringListName',
          width: '150'
        },
        {
          label: '工程名称',
          dataKey: 'engineeringListName',
          width: '200'
        }
      ]
    },
    {
      label: '单位',
      dataKey: 'unit',
      width: '60'
    },
    {
      label: '合同量',
      dataKey: 'num',
      width: '100'
    },
    {
      label: '本月计划',
      dataKey: 'currentMonthNum',
      width: '90'
    },
    {
      label: '本日完成',
      children: [
        {
          label: '主线',
          editable: true,
          dataKey: 'mainLineNum',
          validator (row) {
            if (row.mainLineNum !== undefined && row.mainLineNum !== null && row.mainLineNum !== '') {
              const len = row.unit.split('\\').length
              const mainLineNumList = row.mainLineNum.toString().split('\\')
              if (len !== mainLineNumList.length) {
                return false
              }
              if (!mainLineNumList.every(item => !isNaN(item))) {
                return false
              }
            }
            return true
          },
          width: '110'
        },
        {
          label: '互通',
          editable: true,
          dataKey: 'crossNum',
          validator (row) {
            if (row.crossNum !== undefined && row.crossNum !== null && row.crossNum !== '') {
              const len = row.unit.split('\\').length
              const crossNumList = row.crossNum.toString().split('\\')
              if (len !== crossNumList.length) {
                return false
              }
              if (!crossNumList.every(item => !isNaN(item))) {
                return false
              }
            }
            return true
          },
          width: '110'
        },
        {
          label: '分离',
          editable: true,
          dataKey: 'departureNum',
          validator (row) {
            if (row.departureNum !== undefined && row.departureNum !== null && row.departureNum !== '') {
              const len = row.unit.split('\\').length
              const departureNumList = row.departureNum.toString().split('\\')
              if (len !== departureNumList.length) {
                return false
              }
              if (!departureNumList.every(item => !isNaN(item))) {
                return false
              }
            }
            return true
          },
          width: '110'
        },
        {
          label: '连接线服务区',
          editable: true,
          dataKey: 'serviceNum',
          validator (row) {
            if (row.serviceNum !== undefined && row.serviceNum !== null && row.serviceNum !== '') {
              const len = row.unit.split('\\').length
              const serviceNumList = row.serviceNum.toString().split('\\')
              if (len !== serviceNumList.length) {
                return false
              }
              if (!serviceNumList.every(item => !isNaN(item))) {
                return false
              }
            }
            return true
          },
          width: '110'
        },
        {
          label: '合计',
          dataKey: 'currentDateNum',
          validator (row) {
            if (row.currentDateNum !== undefined && row.currentDateNum !== null && row.currentDateNum !== '') {
              const len = row.unit.split('\\').length
              const currentDateNumList = row.currentDateNum.toString().split('\\')
              if (len !== currentDateNumList.length) {
                return false
              }
              if (!currentDateNumList.every(item => !isNaN(item))) {
                return false
              }
            }
            return true
          },
          formula (row) {
            const len = row.unit.split('\\').length
            let sumList = new Array(len).fill(0)
            if (row.mainLineNum !== undefined && row.mainLineNum !== null && row.mainLineNum !== '') {
              const mainLineNumList = row.mainLineNum.split('\\')
              if (len === mainLineNumList.length) {
                sumList = sumList.map((sum, index) => {
                  return utility.floatAdd(sum, mainLineNumList[index]).toFixed(2)
                })
              }
            }
            if (row.crossNum !== undefined && row.crossNum !== null && row.crossNum !== '') {
              const crossNumList = row.crossNum.split('\\')
              if (len === crossNumList.length) {
                sumList = sumList.map((sum, index) => {
                  return utility.floatAdd(sum, crossNumList[index]).toFixed(2)
                })
              }
            }
            if (row.departureNum !== undefined && row.departureNum !== null && row.departureNum !== '') {
              const departureNumList = row.departureNum.split('\\')
              if (len === departureNumList.length) {
                sumList = sumList.map((sum, index) => {
                  return utility.floatAdd(sum, departureNumList[index]).toFixed(2)
                })
              }
            }
            if (row.serviceNum !== undefined && row.serviceNum !== null && row.serviceNum !== '') {
              const serviceNumList = row.serviceNum.split('\\')
              if (len === serviceNumList.length) {
                sumList = sumList.map((sum, index) => {
                  return utility.floatAdd(sum, serviceNumList[index]).toFixed(2)
                })
              }
            }
            return sumList.join('\\')
          },
          width: '110'
        }
      ]
    }
  ]
}

export default G18SpreadInfo
