import utility from '@/common/utility'
import auth from '@/common/auth'

export default class G18Model {
  constructor (props) {
    if (props) {
      this.resourceId = props.g18Guid
      this.g18Guid = props.g18Guid
      this.planStatExaminationGuid = props.planStatExaminationGuid
      this.engineeringListGuid = props.engineeringListGuid
      this.currentMonthNum = props.currentMonthNum
      this.currentDateNum = props.currentDateNum
      this.mainLineNum = props.mainLineNum
      this.crossNum = props.crossNum
      this.departureNum = props.departureNum
      this.serviceNum = props.serviceNum
      this.sortId = props.sortId
      this.createUserGuid = props.createUserGuid
      this.createTime = props.createTime
      this.tenderGuid = props.tenderGuid
      this.year = props.year
      this.month = props.month
      this.date = props.date
    }
    this.init()
  }

  init () {
    if (!this.resourceId) this.resourceId = ''
    if (!this.g18Guid) this.g18Guid = ''
    if (!this.planStatExaminationGuid) this.planStatExaminationGuid = ''
    if (!this.engineeringListGuid) this.engineeringListGuid = ''
    if (!this.currentMonthNum) this.currentMonthNum = ''
    if (!this.currentDateNum) this.currentDateNum = ''
    if (!this.mainLineNum) this.mainLineNum = ''
    if (!this.crossNum) this.crossNum = ''
    if (!this.departureNum) this.departureNum = ''
    if (!this.serviceNum) this.serviceNum = ''
    if (!this.sortId) this.sortId = 0
    if (!this.createUserGuid) this.createUserGuid = auth.getUserInfo().userGuid
    if (!this.createTime) this.createTime = utility.getCurrentTime()
    if (!this.tenderGuid) this.tenderGuid = ''
    if (!this.year) this.year = 0
    if (!this.month) this.month = 0
    if (!this.date) this.date = 0
  }

  generatePrimaryKey () {
    this.g18Guid = utility.getUuid()
    this.resourceId = this.g18Guid
  }
}
